<template>
<div class="row h-100 w-100 p-0 m-0 d-flex flex-lg-row flex-column flex-sm-column justify-content-center">
    <div class="col-12 p-0 m-0">
      <Navbar />
    </div>
    <div class="col-12 col-lg-8 p-0 m-0 w-100 h-100">
      <Carousel/>
          <div class="card-deck row justify-content-center m-0 p-0">
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card1.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Consultanță</h5>
      <p class="card-text">Avand o experienta indelungata, va pot oferi sfaturi pe întreg procesul de construcție a propriului produs.</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card2.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Consultanță BOM</h5>
      <p class="card-text">Împreună vom optimiza procesul de selecție al componentelor. Astfel vom alege cele mai optime componente pentru un timp redus, eficiență maximă și cat mai puține compromisuri.</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card3.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Prototipuri</h5>
      <p class="card-text">Circuitele prototip sunt realizate din aceleași materiale ca și viitoarele circuite care vor fi realizate în volume mari, validând în felul acesta materia primă, procesele de asamblare, geometria circuitului, ș.a.m.d..</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card4.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Proiectare PCB</h5>
      <p class="card-text">Proiectarea circuitelor electronice este un proces complex și meticulos, unde trebuie să ținem cont de foarte mulți factori, iar la finalul fiecărei proiectări este obligatoriu să avem o documentație bună a întregului proiect, astfel încât acesta să poata fi realizat într-o linie de producție.</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card5.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Producție PCB</h5>
      <p class="card-text">Electronica modernă este tot mai complexă și folosește componente de dimensiuni tot mai reduse. Aprope orice dispozitiv electronic din zilele noastre are un microcontroller și câteva componente SMD. Astfel, circuitele electronice sunt din ce în ce mai mici și din acest motiv este necesară utilizarea cât mai eficientă a cablajelor de circuit imprimat.</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
  <div class="card m-5 p-3" style="width: 25rem;">
    <img class="card-img-top" src="../assets/images/card6.jpg" alt="Card image cap">
    <div class="card-body">
      <h5 class="card-title">Asamblare PCB</h5>
      <p class="card-text">Vă punem la dispoziție servicii de asamblare PCB, atât SMD, cât și THT pentru prototipuri sau producție de preserie în vederea verificării pe deplin a funcționalității produselor înca din faza de prototip.</p>
    </div>
    <router-link class="btn btn-third text-primary" :to="{ name:'Contact'}">Contact</router-link>
  </div>
</div>
    </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Carousel from '../components/Carousel.vue'
import Navbar from "../components/Navbar.vue";

export default {
  name: "HomeView",
  components: {
    Carousel, Navbar
  }
};
</script>

<style scoped>
.container-fluid{
  width:100%;
  padding:0 0;
  margin: 0 0;
}
</style>

<template>
<div class="row p-0 m-0 h-100">
    <nav class="navbar navbar-expand-md fixed-top navbar-dark">
        <div class="container-fluid">
            <router-link class="nav-link navbar-brand text-primary" :to="{ name:'Home'}">
                <img src="../assets/images/logo1.png" width="95" class="rounded img-fluid m-0 p-0" alt="...">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#transparentNav" aria-controls="transparentNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="transparentNav">
                <ul class="navbar-nav ms-4 p-0 ml-auto ">
                    <li class="nav-item active">
                        <router-link class="nav-link text-primary" :to="{ name:'Home'}">Acasa</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link text-primary" :to="{ name:'Contact'}">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
export default {
    mounted() {
     const navbar = document.querySelector('.navbar');
window.onscroll = () => {
    if (window.scrollY > 600) {
        navbar.classList.add('nav-active');
    } else {
        navbar.classList.remove('nav-active');
    }
};
    },
};
</script>

<style scoped>
.nav-active {
    background-color: #14213D;
    transition: background-color 200ms linear;
}
</style>
<template>
  <div class="about">
    <p>About Page</p>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style scoped>
* {
  color: var(--fourth);
}
</style>
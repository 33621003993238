<template>
<div class="row w-100 p-0 m-0 d-flex flex-lg-row flex-column flex-sm-column">
    <div class="col-12 p-0 m-0">
      <StaticNavbar />
    </div>
    <div class="col-12 col-lg-8 p-0 w-100" style="margin-bottom: 70px !important;">
        <div class="row w-100">
            <div class="col-12 col-lg-8 w-100 d-flex justify-content-center">
                <h1 class="mt-3" style="border-bottom: 1px solid #14213D; padding-bottom: 5px;">Contactați-ne</h1>
            </div> 
        </div>
        <div class="row p-0 m-0 d-flex flex-lg-row flex-column flex-sm-column">
            <div class="col-lg-6 align-items-center align-self-center">
                <div class="card mb-3 border-secondary text-primary bg-third ">
                    <div class="card-body">
                        <h5 class="card-title mb-3">Detalii de contact</h5>
                        <h6 class="card-subtitle mb-2">Email</h6>
                        <h5 class="card-text text-secondary my-auto mb-3">@ contact@pcbshop.ro</h5>
                        <h6 class="card-subtitle mb-2">Telefon</h6>
                        <h5 class="card-text text-secondary my-auto mb-3">T: 0765010106</h5>
                        <h6 class="card-subtitle mb-2">Date firma</h6>
                        <table class="table my-auto">
                            <tbody>
                                <tr>
                                    <td>Denumire firmă</td>
                                    <td>S.C Sample Shop PCB S.R.L</td>
                                </tr>
                                <tr>
                                    <td>CIF</td>
                                    <td>RO45686317</td>
                                </tr>
                                <tr>
                                    <td>Nr. Reg. Com.</td>
                                    <td>J3/463/2022</td>
                                </tr>
                                <tr>
                                    <td>Sediu Social</td>
                                    <td>Bibescu Voda, nr.13, bl.B, sc.A, ap.4,
                                        Pitești, Argeș</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="container ms-10 ps-10">
                    <form ref="form" @submit.prevent="handleSubmit">
                        <div class="mb-3">
                            <label for="namecontrol" class="form-label text-secondary">
                                <i class="fa fa-user fa-2x"></i>
                            </label>
                            <input type="text" class="form-control border-secondary" v-model="fullname" id="namecontrol" name="name" placeholder="Nume și Prenume">
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label text-secondary">
                                <i class="fa fa-envelope fa-2x"></i>
                            </label>
                            <input type="email" id="email" class="form-control border-secondary" name="email" placeholder="Email" v-model="email" required>
                            <div v-if="emailError" class="error">{{ emailError }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label text-secondary">
                                <i class="fa fa-mobile-phone fa-2x"></i>
                            </label>
                            <input type="tel" id="phone" class="form-control border-secondary" name="phone" v-model="phone" placeholder="Telefon">
                            <div v-if="phoneError" class="error">{{ phoneError }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="message" class="form-label text-secondary">
                                <i class="fa fa-comment fa-2x"></i>
                            </label>
                            <textarea id="message" class="form-control border-secondary" name="message" v-model="message" rows="5" placeholder="Mesaj" required></textarea>
                        </div>
                        <div class="mb-3">
                            <input class="btn btn-secondary" type="submit" value="Trimite">
                            <div v-if="success" class="success">{{ success }}</div>
                            <div v-if="sendError" class="error">{{ sendError }}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import emailjs from '@emailjs/browser';
import StaticNavbar from "../components/StaticNavbar.vue";

export default {
  name: "ContactView",
  components: {
    StaticNavbar,
  },
  setup() {
        const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const phoneRegExp = /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/

        const email = ref('')
        const success = ref('')
        const sendError = ref('')
        const fullname = ref('')
        const phone = ref('')
        const message = ref('')
        const emailError = ref('')
        const phoneError = ref('')
        const form = ref(null)
        const fileUpload = ref(null)

        const handleSubmit = () => {
            emailError.value = emailRegExp.test(email.value) ? "" : "Adresa de email este incorecta!"
            phoneError.value = phoneRegExp.test(phone.value) ? "" : "Telefon incorect!"
            if(emailError.value === "" && phoneError.value === "")
            {
                //send email
                emailjs.sendForm('service_7q4i4fh', 'template_735dpmg', form.value, 'QRLTschR8MDDzMoo3')
                        .then((result) => {
                            sendError.value = ''
                            success.value = "Mesajul a fost trimis!"
                            fullname.value = ''
                            email.value = ''
                            phone.value = ''
                            message.value = ''
                            fileUpload.value = ''
                            setTimeout(function() {
                                success.value = ''
                            }, 3000);
                        }, (error) => {
                            sendError.value = 'A aparut o eroare va rugam incercati din nou!'
                        });
            }
        }

        return { email, fullname, phone, message, emailError, phoneError, handleSubmit, form, success, sendError, fileUpload }
  }
};
</script>

<style scoped>
.error {
  color: #ff0062;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}

.success {
  color: green;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}
</style>
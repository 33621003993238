<template>
  <div class="row p-0 m-0">
    <div id="carouselExampleCaptions" class="carousel slide p-0" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img :src="pcb5" class="d-block w-100 vh-100" alt="0">
      <div class="carousel-caption d-none d-md-block">
        <p class="text-primary fw-bold">Soluții de calitate.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img :src="pcb4" class="d-block w-100 vh-100" alt="1">
      <div class="carousel-caption d-none d-md-block">
        <p class="text-primary fw-bold">Preț avantajos.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img :src="pcb3" class="d-block w-100 vh-100" alt="2">
      <div class="carousel-caption d-none d-md-block">
        <p class="text-primary fw-bold">Flexibilitate și adaptare după cerințele clientului.</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: "Carousel",
    setup() {
        const pcb3 = ref('')
        pcb3.value = require('../assets/images/pcb3.jpeg')
        const pcb4 = ref('')
        pcb4.value = require('../assets/images/pcb4.jpeg')
        const pcb5 = ref('')
        pcb5.value = require('../assets/images/pcb5.jpeg')

        return { pcb3, pcb4, pcb5 }
  }
}

</script>


<style scoped>
.carousel-inner .carousel-item {
    -webkit-transition: 0s !important;
    -o-transition: 0s !important;
    transition: 0s !important;
}
</style>
<template>
<nav class="navbar navbar-expand-md navbar-dark bg-third">
    <div class="container-fluid">
        <router-link class="nav-link navbar-brand text-primary" :to="{ name:'Home'}">
            <img src="../assets/images/logo1.png" width="95" class="rounded img-fluid m-0 p-0" alt="...">
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#staticNav" aria-controls="staticNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="staticNav">
            <ul class="navbar-nav ms-4 p-0 ml-auto">
                <li class="nav-item active">
                    <router-link class="nav-link text-primary" :to="{ name:'Home'}">Acasa</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link text-primary" :to="{ name:'Contact'}">Contact</router-link>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
export default {
    
};
</script>

<style scoped>

</style>
<template>
  <div class="row p-0 m-0">
      <router-view />
  </div>
</template>

<script>
export default {
};
</script>

<style>
</style>
